<template>
  <div>
    <div class="centerbox">
      <div inputbox>
        <el-input
          class="myinput"
          placeholder="请输入关键字搜索"
          v-model="inputtxt"
          clearable
        >
        </el-input>
        <el-button type="primary" @click="search" icon="el-icon-search"
          >搜索</el-button
        >
      </div>
      <div class="tablebox">
        <el-table :data="tableData" border>
          <el-table-column prop="id" label="ID" width="40"> </el-table-column>
          <el-table-column prop="title" label="标题" width="300">
          </el-table-column>
          <el-table-column prop="cname" label="所属栏目" width="130">
          </el-table-column>
          <el-table-column prop="npic" label="缩略图" width="100">
            <template slot-scope="scope">
              <el-image
                style="width: 60px; height: 60px; margin-bottom: -4px"
                :src="'http://img.hxsh.ikoo.store/' + scope.row.npic"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="权重" width="50">
          </el-table-column>
          <el-table-column prop="lasttime" label="最后编辑时间" width="150">
          </el-table-column>
          <el-table-column :key="Math.random()" label="发布" width="65">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.ispublish"
                active-color="#13ce66"
                inactive-color="#cccccc"
                @change="publish(scope.row.ispublish, scope.row.id)"
              >
              </el-switch>
            </template>
          </el-table-column>

          <!-- <el-table-column :key="Math.random()" label="置顶" width="65">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status2"
                active-color="#13ce66"
                inactive-color="#cccccc"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column :key="Math.random()" label="热门" width="65">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status3"
                active-color="#13ce66"
                inactive-color="#cccccc"
              >
              </el-switch>
            </template>
          </el-table-column> -->

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="pageedit(scope.row.id)"
                >编辑</el-button
              >
              <!-- <el-button size="mini" @click="pageadd()" type="primary"
                >新增</el-button
              > -->
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagingbox">
        <!-- @prev-click="lastpage()"
          @next-click="nextpage()"
          :current-page.sync="myPageIndex"
          @current-change="pagechange(myPageIndex)" -->
        <el-pagination
          class="pageation"
          @current-change="pagechange(pageNum)"
          @prev-click="lastpage()"
          @next-click="nextpage()"
          :current-page.sync="pageNum"
          :page-size="10"
          layout="total,prev, pager, next, jumper"
          :total="this.pagetotal"
        >
        </el-pagination>
      </div>
      <div style="height: 30px"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      value: true,
      inputtxt: "",
      currentPage: 1,
      myPageIndex: 1,
      pagetotal: 0,
      pageNum: 1,
    };
  },

  created() {
    this.getdata();
    this.getM_Index();
    // console.log("vuex存储", this.$store.state.uid);
    console.log(sessionStorage.getItem("username"));
  },
  methods: {
    refreshPageHandler() {
      window.location.reload(); // 第一种方式
    },
    getM_Index() {
      var that = this;
      this.$request({
        url: "/api/News/M_Index?PageSize=10&Page=" + this.pageNum,
        method: "GET",
        params: {},
      })
        .then((res) => {
          if (res.data.status) {
            this.tableData = res.data.list;
            this.pagetotal = res.data.pc.Totals;
          }
          if (res.Message == "已拒绝为此请求授权。") {
            this.$router.push({
              path: "/",
              query: {
                id: 111,
              },
            });
          }
        })
        .finally(() => {});
    },
    getdata() {
      this.$request({
        url: "/api/News/List?PageSize=10&Page=" + this.pageNum,
        method: "GET",
        widthCredentials: true,
        params: {
          // classid: 5,
        },
      })
        .then((res) => {
          if (res.data.status) {
          }
        })
        .finally(() => {});

      // var that = this;
      // $.ajax({
      //   type: "GET",
      //    xhrFields: {
      //       withCredentials: true, //允许跨域带Cookie
      //     },
      //   url:
      //     "https://api.hxsh.ikoo.store/api/News/List?PageSize=10&Page=" +
      //     this.pageNum,
      //   data: {
      //     // classid: 5
      //      },
      //   success: function (data) {
      //     console.log(data);
      //     that.tableData = data.list;
      //     that.pagetotal = data.pc.Totals;
      //   },
      // });
    },
    //文章搜索
    search() {
      this.$request({
        url: "/api/News/M_Index?PageSize=10&Page=" + this.pageNum,
        method: "GET",
        params: {
          keyword: this.inputtxt,
        },
      })
        .then((res) => {
          if (res.data.status) {
            this.tableData = res.data.list;
            this.pagetotal = res.data.pc.Totals;
          }
        })
        .finally(() => {});
    },

    pageedit(val) {
      this.$router.push({
        path: "/newsedit",
        query: {
          id: val,
        },
      });
    },
    pageadd() {
      this.$router.push({
        path: "/newsadd",
        query: {
          id: 111,
        },
      });
    },

    handleDelete(index, rows) {
      console.log(index, rows);
      this.$confirm("此操作将删除该文章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // rows.splice(index, 1);
          this.$request({
            url: "/api/News/M_NewsDelete",
            method: "DELETE",
            withCredentials: true,
            data: {
              id: rows,
            },
          })
            .then((res) => {
              if (res.status) {
                this.getM_Index();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .error((res) => {});
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    publish(val, e) {
      // console.log(val, e);
      var that = this;
      that.publishadd(e);
    },
    publishadd(e) {
      this.$request({
        url: "/api/News/M_NewsPublish",
        method: "POST",
        data: {
          id: e,
        },
      })
        .then((res) => {
          if (res.status) {
            // this.$message({
            //   type: "success",
            //   message: "发布成功",
            // });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .error((res) => {});
    },
    //上一页
    lastpage() {
      this.pageNum--;
      console.log(this.pageNum);
      // this.getdata();
      this.getM_Index();
    },
    //下一页
    nextpage() {
      this.pageNum++;
      console.log(this.pageNum);
      // this.getdata();
      this.getM_Index();
    },
    //选择分页
    pagechange(val) {
      console.log("当前页", val);
      this.pageNum = val;
      // this.getdata();
      this.getM_Index();
    },
  },
};
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  height: auto;
  color: #000;
  overflow: hidden;
}
.el-aside::-webkit-scrollbar {
  display: none;
}
</style>
<style scoped>
.centerbox {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.inputbox {
  display: flex;
}
.tablebox {
  width: 98%;
  margin-left: 1%;
  margin-top: 20px;
  margin-bottom: 30px;
}
.myinput {
  margin-top: 20px;
  width: 300px;
  margin-left: 1%;
  margin-right: 15px;
}
.pagingbox {
  margin-left: 1%;
  height: 40px;
  line-height: 40px;
}
.pagingbox >>> .el-input {
  height: 28px;
  line-height: 28px;
}
</style>
